import request from '@/plugins/axios'


// 文章列表
export const apiCommunityArticleLists = (params: any) => request.get('/community.community_article/lists', { params })

// 添加文章
export const apiCommunityArticleAdd = (params: any) => request.post('/community.community_article/add', params)

// 编辑文章
export const apiCommunityArticleEdit = (params: any) => request.post('/community.community_article/edit', params)

// 删除文章
export const apiCommunityArticleDelete = (params: any) => request.post('/community.community_article/delete', params)

// 文章详情
export const apiCommunityArticleDetail = (params: any) => request.get('/community.community_article/detail', { params })

export const apiCommunityArticleSwitchStatus = (params: any) => request.post('/community.community_article/status', params)